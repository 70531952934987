import React from 'react';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import Error from '@beelineloans/cx-library/src/components/Error';

const Save50K = () => (
  <NavOnlyTemplate>
    <Error code={404} />
  </NavOnlyTemplate>
);

export default Save50K;
